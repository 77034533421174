import { 
    LOAD_LIST_PRODUCT,
    LOAD_CATEGORY_PRODUCT,
  } from '../../constants/ActionTypes';
  
import { fetchApi } from "../../utils/api";
export async function loadMoreProduct(payload) {
    try {
        let response = await fetchApi(`/app/product/list`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
}

export function loadListCategory(payload) {
    return  {
        type: LOAD_CATEGORY_PRODUCT,
        payload
    };
}
export function loadListProduct(payload) {
    return  {
        type: LOAD_LIST_PRODUCT,
        payload
    };
}