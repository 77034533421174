import { take, put, fork } from 'redux-saga/effects';
import { SAVE_INFO_USER, SAVE_LOGIN } from '@/constants/ActionTypes';
import { fetchApi } from '../../../utils/api';
import { actionSaveInfoUser } from '@/redux/actions/auth';
import api from '@/constants/ApiUrlForBE';

/**
 * userList saga
 */
export function* fetchUserInfo() {
  while (true) {
    // yield take(SAVE_INFO_USER);
    try {
      const res = yield fetchApi('/app/auth/user-info');
      yield put(actionSaveInfoUser(res.data));
    } catch (e) {
      yield put(e);
    }
    yield take(SAVE_INFO_USER);
  }
}

const authSagas = [fork(fetchUserInfo)];

export default authSagas;
