import { take, put, fork } from 'redux-saga/effects';
import {
  LOAD_LIST_PRODUCT,
  LOAD_CATEGORY_PRODUCT,
} from '@/constants/ActionTypes';
import { loadListProduct, loadListCategory } from '@/redux/actions/product';
import api from '@/constants/ApiUrlForBE';

/**
 * userList saga
 */
export function* fetchProductList() {
  while (true) {
    try {
      const res = yield fetch(api.getListProduct, {
        headers: {
          language: 'en',
        },
      });
      const data = yield res.json();
      yield put(loadListProduct(data.data));
    } catch (e) {
      yield put(e);
    }
    yield take(LOAD_LIST_PRODUCT);
  }
}
export function* fetchCategoryProductList() {
  while (true) {
    try {
      const res = yield fetch(api.getListCategory, {
        headers: {
          language: 'en',
        },
      });
      const data = yield res.json();
      yield put(loadListCategory(data.data));
    } catch (e) {
      yield put(e);
    }
    yield take(LOAD_CATEGORY_PRODUCT);
  }
}

const productSagas = [fork(fetchProductList), fork(fetchCategoryProductList)];

export default productSagas;
