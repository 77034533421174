import { 
    SAVE_MENU_SIDEBAR,
    SAVE_LOADINGS,
    SAVE_SOCIAL,
    SAVE_TITLE_HEADER,
    SAVE_FOOTER
} from '../../constants/ActionTypes';
  
import { fetchApi } from "../../utils/api";
export function changeMenu(payload) {
    return {
        type: SAVE_MENU_SIDEBAR,
        payload
    };
}

export function changeHeader(payload) {
    return {
        type: SAVE_TITLE_HEADER,
        payload
    };
}
export const actionLoading = (payload) => ({
    type: SAVE_LOADINGS,
    payload,
});

export const getSocial = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/setting/social`, "get", payload);
        if (response.code === 200){
            dispatch(actionSaveSocial(response.data));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export const getFooterByUrl = async (payload, dispatch) =>  {
    try {
        let response = await fetchApi(`/app/post/text-footer?url=${payload}`, "get");
        if (response.code === 200){
            dispatch(actionGetFooter(response.data));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export const actionGetFooter = (payload) => ({
    type: SAVE_FOOTER,
    payload,
});

export const actionSaveSocial = (payload) => ({
    type: SAVE_SOCIAL,
    payload,
});