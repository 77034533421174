const initialState = {
    hot: [],
    news: null,
};
import {
    LOAD_HOT_NEWS, LOAD_NEWS_HOME
  } from '@/constants/ActionTypes';
 
  
  const news = (state = initialState, { type, payload }) => {
    let newState = Object.assign({}, state);
    switch (type) {
    case LOAD_HOT_NEWS:
        return {
          ...newState,
          hot: payload
        };
      case LOAD_NEWS_HOME:
        return {
          ...newState,
          news: payload
        };
      default:
        return { ...newState };
    }
  };

  

export default news;