import CryptoJS from "crypto-js";
const cryptkey = 'HNASKKGSGY632$%92832DSAJS632GAS&';
const iv = 'NVHAYHSA532%$FAS';

export const encrypted =  (data) => {
    var encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(cryptkey),
        {
            keySize: 256 / 8,
            iv:  CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return {
        timestamp : data,
        checksum : encryptedData.ciphertext.toString(CryptoJS.enc.Base64)
    };
};