import { UPDATE_CHART_OVERVIEW, UPDATE_CHART_PRICE_GOLD, UPDATE_CHART_GOLD } from '@/constants/ActionTypes';

const initialState = {
    overview: {},
    price: [],
    chart: [],
};
const chartReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CHART_OVERVIEW:
            return {
                ...state,
                overview: action.payload || {}
            }; 
        case UPDATE_CHART_PRICE_GOLD:
            return {
                ...state,
                price: action.payload || {}
            };
        case UPDATE_CHART_GOLD:
            return {
                ...state,
                chart: action.payload || {}
            };
        default:
            return { ...state };
    }
};
export default chartReducer;