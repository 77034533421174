import {
    UPDATE_SELL_GOLD, UPDATE_BUY_GOLD, UPDATE_WITH_DRAW,
    UPDATE_RECHARGE, UPDATE_GOLD_TRANSFER, UPDATE_BUY_PRODUCT, UPDATE_MONEY
} from '@/constants/ActionTypes';

const initialState = {
    sellGold: {},
    buyGold: {},
    withdraw: {},
    recharge: {},
    goldTransfer: {},
    buyProduct: {},
    withdrawMoney: {}

};
const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SELL_GOLD:
            return {
                ...state,
                sellGold: action.payload || {}
            };
        case UPDATE_BUY_GOLD:
            return {
                ...state,
                buyGold: action.payload || {}
            };
        case UPDATE_WITH_DRAW:
            return {
                ...state,
                withdraw: action.payload || {}
            };
        case UPDATE_RECHARGE:
            return {
                ...state,
                recharge: action.payload || {}
            };
        case UPDATE_GOLD_TRANSFER:
            return {
                ...state,
                goldTransfer: action.payload || {}
            };
        case UPDATE_BUY_PRODUCT:
            return {
                ...state,
                buyProduct: action.payload || {}
            };
        case UPDATE_MONEY:
            return {
                ...state,
                withdrawMoney: action.payload || {}
            };
        default:
            return { ...state };
    }
};
export default historyReducer;