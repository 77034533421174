import axiosInstance from './axiosInstance';
import { encrypted } from './encryp_aes';

const API_URL =
  process.env.NEXT_PUBLIC_API_SERVER_URL || 'https://api.hanagold.vn';
const renderHeaderCheckSum = async () => {
  const time_stamp = Date.now().toString();
  const result = await encrypted(time_stamp);
  return result;
};
export const fetchApi = async (url, method = 'get', body, headers) => {
  let lang = 'en';
  try {
    lang = localStorage.getItem('lang');
  } catch (err1) {
    console.log('xx');
  }

  try {
    const { timestamp, checksum } = await renderHeaderCheckSum();
    let opts = {
      method,
      url: `${API_URL.trim()}${url}`,
      timeout: 1 * 1000 * 60, // 1phut
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        timestamp,
        checksum,
        language: lang,
      },
    };
    if (headers) {
      opts = {
        ...opts,
        headers: {
          ...headers,
          [headers.key]: headers.value,
        },
      };
    }
    if (method === 'get') {
      opts.params = body;
    } else {
      opts.data = body;
    }

    let fetchdata = await axiosInstance(opts);
    if (fetchdata.data.code !== 200) {
      return fetchdata.data;
    }
    return fetchdata.data;
  } catch (error) {
    let { response } = error;
    if (response) {
      return response.data;
    }
    return error;
  }
};

export const fetchApiUpload = async (url, method = 'get', body) => {
  try {
    let opts = {
      method,
      url: `${API_URL.trim()}${url}`,
      timeout: 1 * 1000 * 60, // 1phut
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };
    if (method === 'get') {
      opts.params = body;
    } else {
      opts.data = body;
    }
    let fetchdata = await axiosInstance(opts);
    if (fetchdata.data.code !== 200) {
      return fetchdata.data;
    }
    return fetchdata.data;
  } catch (error) {
    let { response } = error;
    if (response) {
      return response.data;
    }
    return error;
  }
};
