import { LOAD_LAST_KYC, SAVE_KYC, SAVE_ADDRESS, SAVE_PROVINCES, SAVE_USER_REF, SAVE_DETAIL_PARTNERS } from '@/constants/ActionTypes';

  const initialState = {
    lastKyc: null,
    address: [],
    kyc : null,
    detailPartners : null,
    user_ref : null,
    provinces: []
};
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_LAST_KYC:
            return {
                ...state,
                lastKyc: action.payload
            }; 
        case SAVE_DETAIL_PARTNERS:
            return {
                ...state,
                detailPartners: action.payload
            }; 
        case SAVE_USER_REF:
            return {
                ...state,
                user_ref: action.payload
            }; 
        case SAVE_ADDRESS:
            return {
                ...state,
                address: action.payload
            };
        case SAVE_KYC:
            return {
                ...state,
                kyc: action.payload
            };
        case SAVE_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            };
        default:
            return { ...state };
    }
};
export default userReducer;