const initialState = {
  listBank: [],
  listBankUser: [],
  listBankTransfer: [],
  listAgency: [],
  listAllAgency: [],
  exchangeRate: {},
  withDraw: {},

};
import {
  LOAD_LIST_BANK, LOAD_LIST_BANK_TRANSFER, LOAD_LIST_AGENCY,
  LOAD_EXCHANGE_RATE, SET_WITH_DRAW, LOAD_ALL_AGENCY, LOAD_LIST_BANK_USER,
  CANCEL_BANK_TRANSFER,
} from '@/constants/ActionTypes';


const payment = (state = initialState, { type, payload }) => {
  let newState = Object.assign({}, state);
  switch (type) {
    case LOAD_LIST_BANK:
      return {
        ...newState,
        listBank: payload
      };
    case LOAD_LIST_BANK_USER:
      return {
        ...newState,
        listBankUser: payload
      };
    case LOAD_ALL_AGENCY:
      return {
        ...newState,
        listAllAgency: payload
      };
    case LOAD_LIST_BANK_TRANSFER:
      return {
        ...newState,
        listBankTransfer: payload
      };
    case SET_WITH_DRAW:
      return {
        ...newState,
        withDraw: payload
      };
    case LOAD_LIST_AGENCY:
      return {
        ...newState,
        listAgency: payload
      };
    case LOAD_EXCHANGE_RATE:
      return {
        ...newState,
        exchangeRate: payload
      };
    case CANCEL_BANK_TRANSFER:
      return {
        ...newState,
        exchangeRate: payload
      };

    default:
      return { ...newState };
  }
};



export default payment;