import { SAVE_INFO_USER, SAVE_LOGIN, SAVE_LIST_USER_LOGIN_PERMISSION, SHOW_POPUP_LOGIN, FORGOT_PASSWORD } from '@/constants/ActionTypes';

const initialState = {
    token: null,
    infoUser: null,
    listUserLoginPermission: [],
    showLogin: 0,
    sendPassword: {},
};
const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_INFO_USER:
            return {
                ...state,
                infoUser: action.payload
            };
        case SHOW_POPUP_LOGIN:
            return {
                ...state,
                showLogin: action.payload
            };
        case SAVE_LOGIN:
            return {
                ...state,
                token: action.payload
            };
        case SAVE_LIST_USER_LOGIN_PERMISSION:
            return {
                ...state,
                listUserLoginPermission: action.payload
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                exchangeRate: action.payload
            };
        default:
            return { ...state };
    }
};
export default homeReducer;