import { fetchApi } from '../../utils/api';
import {
  SAVE_INFO_USER,
  SAVE_LIST_USER_LOGIN_PERMISSION,
  SAVE_LOGIN,
  SHOW_POPUP_LOGIN,
  FORGOT_PASSWORD,
} from '../../constants/ActionTypes';
import { message } from 'antd';

export const actionLogout = async dispatch => {
  try {
    localStorage.removeItem('token');
    dispatch(actionSaveInfoUser(null));
    dispatch(actionSaveListUserLoginPermission(null));
    dispatch(actionSaveLogin(false));
    return;
  } catch (error) {
    alert(error || error?.message);
  }
};
export const actionRegister = async payload => {
  try {
    const response = await fetchApi('/app/auth/register', 'post', payload);

    if (response.code !== 200) {
      return response?.message || 'ERORR';
    }
    const token = response?.data?.token || null;
    localStorage.setItem('tokenReg', token);
    return 0;
  } catch (error) {
    alert(error || error?.message);
  }
};
export const actionResendOTPRegister = async payload => {
  try {
    const tokenReg = localStorage.getItem('tokenReg');
    const response = await fetchApi('/app/auth/register/resend-otp', 'post', {
      token: tokenReg,
    });

    if (response.code !== 200) {
      return response?.message || 'ERORR';
    }
    const token = response?.data?.token || null;
    localStorage.setItem('tokenReg', token);
    return 0;
  } catch (error) {
    alert(error || error?.message);
  }
};
export const actionVerifyOtp = async payload => {
  try {
    const response = await fetchApi(
      '/app/auth/register/verify-otp',
      'post',
      payload,
    );

    if (response.code !== 200) {
      return response?.message || 'ERORR';
    }
    const token = response?.data?.token || null;
    localStorage.setItem('token', token);
    return 0;
  } catch (error) {
    alert(error || error?.message);
  }
};

export const actionLogin = async payload => {
  try {
    const response = await fetchApi('/app/auth/login', 'post', payload);

    if (response.code !== 200) {
      return response?.message || 'ERORR';
    }
    const token = response?.data?.token || null;
    localStorage.setItem('token', token);
    return 0;
  } catch (error) {
    alert(error || error?.message);
  }
};

export const actionLoginByToken = async () => {
  try {
    const response = await fetchApi(`/app/auth/user-info`, 'get');
    if (response?.code !== 200) {
      return checkErrorCode(response?.code, response?.message);
    }
    return response?.data;
  } catch (error) {
    alert(error || error?.message);
  }
};

export const actionSaveInfoUser = payload => ({
  type: SAVE_INFO_USER,
  payload,
});

export const actionSaveLogin = payload => ({
  type: SAVE_LOGIN,
  payload,
});

export const actionSaveListUserLoginPermission = payload => ({
  type: SAVE_LIST_USER_LOGIN_PERMISSION,
  payload,
});

export const actionSaveShowPopupLogin = payload => ({
  type: SHOW_POPUP_LOGIN,
  payload,
});

export const checkErrorCode = async (code, errorMessage) => {
  switch (code) {
    case 400: {
      break;
    }
    case 401: {
      localStorage.removeItem('token');
      break;
    }
    default:
      break;
  }
};
export function forgotPassWord(payload) {
  return {
    type: FORGOT_PASSWORD,
    payload,
  };
}
export const actionSendAccount = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/auth/forgot-password`, 'post', payload);
    if (response.code !== 200) {
      return response;
    }
    await dispatch(forgotPassWord(response.data));
    return response;
  } catch (error) {
    return {
      rows: [],
    };
  }
};

export const actionSendOtpForgotPassword = async payload => {
  try {
    // const tokenReg = localStorage.getItem('tokenReg');
    const response = await fetchApi(
      '/app/auth/forgot-password/verify-otp',
      'post',
      { account: payload.account, otp: payload.otp },
    );
    if (response.code === 200) {
      const token = response?.data?.token || null;
      localStorage.setItem('tokenReg', token);
    }
    return response;
  } catch (error) {
    alert(error || error?.message);
  }
};
export const actionChangePassword = async (payload, dispatch) => {
  console.log('payload', payload);
  try {
    let response = await fetchApi(
      `/app/auth/forgot-password/new-password`,
      'post',
      payload,
    );
    if (response.code !== 200) {
      return message?.response?.message;
    }
    await dispatch(forgotPassWord(response.data));
    return response;
  } catch (error) {
    return {
      rows: [],
    };
  }
};
