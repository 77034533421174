import { AGENCY_LIST_SEND_GOLD, AGENCY_LIST_WITH_DRAW_GOLD, AGENCY_LIST_COMMISSION, REGISTER_AGENCY } from '@/constants/ActionTypes';

const initialState = {
    agencyListSendGold: null,
    agencyListWithDrawGold: null,
    agencyListCommission: null,
    checkAgency: null,
};
const agencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case AGENCY_LIST_SEND_GOLD:
            return {
                ...state,
                agencyListSendGold: action.payload
            };
        case AGENCY_LIST_WITH_DRAW_GOLD:
            return {
                ...state,
                agencyListWithDrawGold: action.payload
            };
        case AGENCY_LIST_COMMISSION:
            return {
                ...state,
                agencyListCommission: action.payload
            };
        case REGISTER_AGENCY:
            return {
                ...state,
                checkAgency: action.payload
            };
        default:
            return { ...state };
    }
};
export default agencyReducer;