const initialState = {
    listStore: [],
};
import {
    LOAD_LIST_STORE
  } from '@/constants/ActionTypes';
 
  
  const store = (state = initialState, { type, payload }) => {
    let newState = Object.assign({}, state);
    switch (type) {
    case LOAD_LIST_STORE:
        return {
          ...newState,
          listStore: payload
        };
      default:
        return { ...newState };
    }
  };

  

export default store;